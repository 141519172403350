import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Pages from "./Pages/index";
import Error404 from "./Pages/404";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import ForgotPasswordPage from "./Auth/ForgotPassword";
import EmailValidation from "@jumbo/components/Common/authComponents/EmailValidation";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Route
      {...rest}
      render={props =>
        authUser ?
          !authUser.is_email_validated && rest.location.pathname != "/email" ?
            <Redirect
              to={{
                pathname: "/email",
                state: { from: props.location }
              }}
            />
            : authUser.is_email_validated && !authUser.plano &&
              rest.location.pathname != "/app/planos" ? (
              <Redirect
                to={{
                  pathname: "/app/planos",
                  state: { from: props.location }
                }}
              />
            )

            : authUser.is_email_validated && authUser.plano && rest.location.pathname == "/email" ?
                <Redirect
                  to={{
                    pathname: "/app/dashboards",
                    state: { from: props.location }
                  }}
                />
                : (
                  <Component {...props} />
                ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )}
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === "" || location.pathname === "/") {
    return <Redirect to={"/app/dashboards"} />;
  } else if (
    authUser &&
    ["/signin", "/signup", "/forgot-password"].includes(location.pathname)
  ) {
    return <Redirect to={"/app/dashboards"} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute path={"/email"} component={EmailValidation} />
        <RestrictedRoute path={"/app"} component={Pages} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
