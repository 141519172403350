import React from "react";

//PROD
export const URL_SOCKET = "https://websocket.multdesk.com.br";
export const URL = "https://api.multdesk.com.br";
export const URL_STORAGE = "https://storage.multdesk.com.br";

export const URL_CHAT = "https://chat.multdesk.com.br";
export const URL_TUTORIAL = "https://multdesk.tawk.help"

/// HML
// export const URL_SOCKET = "https://hmlwebsocket.multdesk.com.br";
// export const URL = "https://hmlapi.multdesk.com.br";

/// LOCAL
// export const URL_SOCKET = "http://localhost:3002";
// export const URL = "http://localhost:3001";
