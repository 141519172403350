import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import {
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser
} from "../../../redux/actions/Auth";
import React from "react";
import axios from "./config";

const JWTAuth = {
  onRegister: ({ name, email, password, phone, turnstileToken }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post("usuario", {
          email: email,
          senha: password,
          nome: name,
          phone: phone
        }, {
          headers: {
            cloudflare: turnstileToken
          }
        })
        .then(({ data }) => {
          if (data.user) {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.user.token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.user.token));
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function (error) {
          console.log(error)
          dispatch(fetchError(error.response.data.message));
        })
        .catch(function (error) {
          console.log(error)
          dispatch(fetchError("Erro ao tentar realizar o registro!"));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post("auth/login", {
            email: email,
            password: password
          })
          .then(({ data }) => {
            if (data.user) {
              localStorage.setItem("token", data.user.token);
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.user.token;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, data.user.token));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function (error) {
            dispatch(fetchError(error.response.data.message));
          })
          .catch(err => dispatch(fetchError("Erro ao realizar login!")));
      } catch (error) {
        dispatch(fetchError("Erro ao realizar login!"));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post("auth/logout")
        .then(({ data }) => {
          if (!data.error) {
            dispatch(fetchSuccess());
            localStorage.removeItem("token");
            dispatch(setAuthUser(null));
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function (error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .post("auth/me")
        .then(({ data }) => {
          if (data.user) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data.user));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function (error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: ({ email }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post("auth/forgot-password", { email })
        .then(() => {
          dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess());
        })
        .catch(function (error) {
          dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess());
        });
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  }
};

export default JWTAuth;
