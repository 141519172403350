import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { Box, CircularProgress, fade } from "@material-ui/core";
import AuthWrapper from "./AuthWrapper";
import { makeStyles } from "@material-ui/core/styles";
import { AuhMethods } from "../../../../services/auth";
import { CurrentAuthMethod } from "@jumbo/constants/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../../config";
import Link from "@material-ui/core/Link";
import { NotificationManager } from "react-notifications";
import { setValidatedEmail } from "redux/actions/Auth";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import EmailIcon from '@mui/icons-material/Email';
import axios from "services/auth/jwt/config";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const LAYOUT = {
    multdesk: {
        logo: "/images/multdesk/multdesk.png",
        name: "Multdesk",
        site: "https://multdesk.com.br",
        cnpj: "41.496.228/0001-00",
        link:
            "https://cadastroempresa.com.br/cnpj/41.496.228/0001-00-mdl-inteligencia-de-negocios-ltda-mdl-desenvolvimento-estrategico-e-inteligencia-de-negocios-ltda"
    }
};

function Copyright() {
    return (
        <React.Fragment>
            <Typography variant="body2" color="textSecondary" align="center">
                <Link
                    color="inherit"
                    target="_blank"
                    href={LAYOUT[config.EMPRESA].link}
                >
                    CNPJ: {LAYOUT[config.EMPRESA].cnpj}
                </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href={LAYOUT[config.EMPRESA].site}>
                    {LAYOUT[config.EMPRESA].name}
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    "@global": {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: "none"
        }
    },
    authContent: {
        padding: 30,
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: props => (props.variant === "default" ? "50%" : "100%"),
            order: 1
        },
        [theme.breakpoints.up("xl")]: {
            padding: 50
        }
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    toolbar: {
        flexWrap: "wrap"
    },
    toolbarTitle: {
        flexGrow: 1
    },
    link: {
        margin: theme.spacing(1, 1.5)
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6)
    },
    heroContentDuracion: {
        padding: theme.spacing(0, 0, 6)
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[700]
    },
    cardPricing: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        marginBottom: theme.spacing(2)
    },
    titleRoot: {
        marginBottom: 14,
        color: theme.palette.text.primary
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6)
        }
    }
}));

const EmailValidation = ({ children }) => {
    const classes = useStyles({});
    const dispatch = useDispatch();
    const { authUser } = useSelector(({ auth }) => auth);

    // Estado para controlar se o botão está desativado ou não
    const [disabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);

    // Estado para armazenar o tempo restante
    const [timeLeft, setTimeLeft] = useState(0);
    const [codigo, setCodigo] = useState("");
    const [loading, setLoading] = useState(false)

    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const onLogoutClick = () => {
        dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    };

    const onSubmit = () => {
        setLoading(true)

        axios.put("/auth/email", {
            code: codigo
        }).then(async success => {
            NotificationManager.success("Email Validado!");
            setValidated(true)
            setLoading(false)

            await delay(1000)

            dispatch(setValidatedEmail({ ...authUser, is_email_validated: true }))
        }).catch(err => {
            setLoading(false)
            NotificationManager.error(err.response.data.message || "Erro ao validar código!")
        })
    }

    const onSendEmail = (resend = false) => {
        setDisabled(true);
        if (resend) {
            axios.patch("auth/email")
                .then(success => {
                    NotificationManager.success(success.data.message)
                })
                .catch(err => {
                    NotificationManager.error(err.data.message)
                })
        } else {
            axios.post("/auth/email")
                .then(success => success)
                .catch(err => err)
        }

        setTimeLeft(120);
    }

    // envia o email assim que chega na página.
    useEffect(() => {
        onSendEmail()
    }, [])


    useEffect(() => {
        // Se o tempo restante for maior que 0, iniciar o temporizador
        if (timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);

            // Limpar o temporizador para evitar acúmulos
            return () => clearTimeout(timer);
        } else {
            // Quando o tempo acabar, reabilitar o botão
            setDisabled(false);
        }
    }, [timeLeft]);

    return (
        <GridContainer>
            <Grid item xs={12}>
                <CssBaseline />
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    className={classes.appBar}
                >
                    <Toolbar className={classes.toolbar}>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.toolbarTitle}
                        >
                            MULTDESK
                        </Typography>
                        <Button
                            href="#"
                            color="primary"
                            variant="outlined"
                            onClick={onLogoutClick}
                            className={classes.link}
                        >
                            Sair
                        </Button>
                    </Toolbar>
                </AppBar>

                <AuthWrapper variant={"mobile"}>
                    <Box className={classes.authContent} justifyContent="space-between">
                        <Typography component="div" variant="h2" className={classes.titleRoot}>
                            Confirme seu email
                        </Typography>

                        <Typography component="p" variant="h6">
                            Um email foi enviado para <Typography component="u">{authUser.email}</Typography> contendo o código de ativação de sua conta.
                        </Typography>
                        <TextField
                            type="text"
                            label={"Código de Ativação"}
                            fullWidth
                            placeholder="_ _ _ _ _ _"
                            onChange={event => setCodigo(event.target.value.toUpperCase())}
                            value={codigo}
                            margin="normal"
                            variant="outlined"
                            style={{
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}
                        />
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginTop={"15px"}
                            mb={5}
                        >

                            <Button variant="text" color="primary" fullWidth startIcon={loading ? <CircularProgress size="18px" /> : validated ? <MarkEmailReadIcon color="success" /> : <EmailIcon />} onClick={onSubmit} disabled={loading || validated}>
                                {validated ? "" : "CONTINUAR"}
                            </Button>
                        </Box>
                        {!validated && <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={5}
                        >
                            <Button variant="text" color="inherit" fullWidth onClick={() => onSendEmail(true)} disabled={disabled}>
                                {disabled ? `⏰ Aguarde ${timeLeft} segundos para solicitar novamente` : 'REENVIAR'}
                            </Button>
                        </Box>}
                        <Typography component="p" variant="h6" align="center">
                            Ao se cadastrar você declara estar de acordo com nossos
                            Termos de Uso e Política de Privacidade.
                        </Typography>
                        <Typography component="p" variant="h6" align="center">
                            <a href="mailto:support@multdesk.com.br">support@multdesk.com.br</a>
                        </Typography>
                    </Box>
                </AuthWrapper>
                <Container maxWidth="md" component="footer" className={classes.footer}>
                    <Box mt={5}>
                        <Copyright />
                    </Box>
                </Container>
            </Grid>
        </GridContainer>
    );
};

export default EmailValidation;